import { EMPLOYER_BASE_PATH } from '../employer/constants';

export const REFERRAL_BASE_PATH = `${EMPLOYER_BASE_PATH}/referral`;

export const REFERRAL_PIPELINE_BASE_PATH = `${REFERRAL_BASE_PATH}/pipeline`;
export const REFERRAL_CAMPAIGNS_BASE_PATH = `${REFERRAL_BASE_PATH}/campaigns`;
export const REFERRAL_INCENTIVES_BASE_PATH = `${REFERRAL_BASE_PATH}/incentives`;

export const workerPageHeaderDefault =
  '<p style="text-align: center"><strong><span style="font-size: 16px">Know someone that could be a great fit for <span data-type="workerAttr" data-id="name" data-label="company name" data-tag="company" data-datatype="string">{{ company.name | default: "" }}</span>?</span></strong><br>Refer them using your general referral link or you can refer to an opening below!</p>';
export const applicantLandingPageDefault =
  '<p style="text-align: center"><strong><span style="font-size: 16px">Come work with <span data-type="workerAttr" data-id="firstname" data-label="worker first name" data-tag="worker" data-datatype="string">{{ worker.firstname | default: "" }}</span> at <span data-type="workerAttr" data-id="name" data-label="company name" data-tag="company" data-datatype="string">{{ company.name | default: "" }}</span>!</span></strong><br><span data-type="workerAttr" data-id="firstname" data-label="worker first name" data-tag="worker" data-datatype="string">{{ worker.firstname | default: "" }}</span> thinks you’d be an amazing fit at <span data-type="workerAttr" data-id="name" data-label="company name" data-tag="company" data-datatype="string">{{ company.name | default: "" }}</span>!</p>';
export const smsMessageDefault =
  '<p>Hi <span data-type="workerAttr" data-id="firstname" data-label="worker first name" data-tag="worker" data-datatype="string">{{ worker.firstname | default: "" }}</span>, Check out our new referrals program in your worker portal!</p>';
export const relationQuestionDefault =
  'What is your relationship with the person referring you?';

export const smsDefaultContent = `<p>Hi, <span data-type="workerAttr" data-id="firstname" data-label="worker name" data-tag="worker" data-datatype="string">{{ worker.firstname | default: "" }}</span>! <span data-type="workerAttr" data-id="name" data-label="company name" data-tag="company" data-datatype="string">{{ company.name | default: "" }}</span> needs more great people like you, and we just promoted new high priority openings for you to share.</p>`;
export const emailDefaultSubject =
  'Refer your community to these new high priority openings';
export const emailDefaultContent = `<p><span data-type="workerAttr" data-id="name" data-label="company name" data-tag="company" data-datatype="string">{{ company.name | default: "" }}</span> needs more great people like you, and we just promoted new high priority openings for you to share. Visit your portal link below to view &amp; share the latest high priority openings with your network. </p><p>Thank you for being a value member of the team!</p>`;
export const adHocEmailContent =
  '<p>Hi <span data-type="workerAttr" data-id="firstname" data-label="worker first name" data-tag="worker" data-datatype="string">{{ worker.firstname | default: "" }}</span>,</p><p>Just a quick reminder: if you know someone who would thrive at <span data-type="workerAttr" data-id="name" data-label="company name" data-tag="company" data-datatype="string">{{ company.name | default: "" }}</span>, refer them to us! Share an opening link through the referrals portal below.</p><p>Not only could you earn a bonus, but you\'ll also play a key role in shaping our future with talented new team members.</p><p>Thank you for your continued dedication and support!</p>';
export const adHocEmailSubject =
  'Reminder: Refer your network to these promoted jobs for a bonus!';
export const adHocSmsContent =
  '<p>Hi <span data-type="workerAttr" data-id="firstname" data-label="worker first name" data-tag="worker" data-datatype="string">{{ worker.firstname | default: "" }}</span>! A friendly reminder: refer your qualified network to <span data-type="workerAttr" data-id="name" data-label="company name" data-tag="company" data-datatype="string">{{ company.name | default: "" }}</span> &amp; you could earn a bonus!</p>';
